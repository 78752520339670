<template>
  <div class="agentContainer">
    <div class="left">
      <el-scrollbar>
        <div
          v-for="(item, index) in publishers"
          :key="index"
          class="publishItem"
          @click="chooseUser(item)"
          :class="{ active: item._id.$id === user_id }"
        >
          <div class="pulish_name">
            {{ item.user_nickName || item.user_email }}
            <div class="time">
              {{ item.recentTime || "No recent chat time" }}
            </div>
          </div>
          <div class="content_txt">
            {{ item.recentMessage || "No dialog" }}
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div
      class="main"
      v-loading="loading"
      :element-loading-text="loadtitle"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="messageContainer" id="messageContainer">
        <div
          v-for="(item, index) in currentMessageList"
          :key="index"
          class="messageItem"
          :class="{ right: item.role === 'user' }"
        >
          <div v-if="item.role === 'assistant'">
            <div class="chatusername">{{ current_user_name }}</div>
            <el-button
              v-if="item.relateData"
              @click="showRelateData(item)"
              style="background: #ec652b; border-color: #ec652b; color: #fff"
              >Related Data</el-button
            >
            <div
              style="margin-top: 10px"
              class="leftMessageItem"
              v-html="changeToHtml(item.content)"
            ></div>
            <div v-if="!item.content" class="leftMessageItem">
              Generating...
            </div>
          </div>
          <div v-if="item.role === 'user'" class="right1">
            <div class="chatusername">{{ user_email }}</div>
            <div
              class="rightMessageItem"
              v-html="changeToHtml(item.formatContent)"
            ></div>
          </div>
        </div>
      </div>
      <div class="inputC">
        <el-input
          resize="none"
          type="textarea"
          v-model="currentM"
          class="myInput"
        ></el-input>
        <el-button type="primary" class="mybutton" @click="sendMessage()">
          Send
        </el-button>
      </div>
    </div>
    <el-dialog title="Related Data" :visible.sync="showRelate" width="50%">
      <el-tabs>
        <el-tab-pane
          v-for="(item, index) in relateData"
          :key="index"
          :label="'data ' + (index + 1)"
        >
          <div
            v-html="item.content"
            style="height: 400px; overflow-y: scroll"
          ></div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRelatedDataFromVectorBase,
  getAllPublishers,
  saveFailChat,
} from "../api/reff.js";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { getUserEmail } from "../utils/store.js";
import dayjs from "dayjs";
export default {
  data() {
    return {
      user_email: getUserEmail(),
      publishers: [],
      currentMessageList: [],
      currentM: "",
      isGenerate: false,
      loading: false,
      user_id: "",
      current_user_name: "",
      loadtitle: "Generating...",
      showRelate: false,
      relateData: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    chooseUser(item) {
      this.user_id = item._id.$id;
      this.current_user_name = item.user_nickName || item.user_email;
      this.currentMessageList = item.messages ? item.messages : [];
      this.currentM = "";
      this.loading = false;
    },
    showRelateData(item) {
      this.relateData = item.relateData;
      this.showRelate = true;
    },
    fetchData() {
      getAllPublishers().then((res) => {
        if (res.data.code === 0) {
          this.publishers = res.data.data;
        }
      });
    },
    changeToHtml(data) {
      if (data) {
        if (data.indexOf("```") !== -1) {
          const count = data.split("```").length - 1;
          if (count % 2 === 0) {
            return window.marked.parse(data);
          } else {
            return window.marked.parse(data + "\n\n```");
          }
        } else {
          return window.marked.parse(data);
        }
      } else {
        return "";
      }
    },
    scrollToBottom() {
      const dom = document.getElementById("messageContainer");
      this.$nextTick(() => {
        dom.scrollTop = dom.scrollHeight;
      });
    },
    addToPublish(data) {
      let user = this.publishers.find((item) => item._id.$id === this.user_id);
      if (user) {
        user.messages = this.currentMessageList;
        if (data.role === "assistant") {
          user.recentMessage = data.content;
          user.recentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
        }
      }
    },
    async sendMessage() {
      if (!this.currentM) {
        this.$message.error("Please enter the content");
        return;
      }
      this.loadtitle = "Search the Knowledge Base ...";
      this.loading = true;
      let queryContent = [
        ...this.currentMessageList.map((item) => item.content),
        {
          role: "user",
          content: this.currentM,
        },
      ];
      let relateData = await getRelatedDataFromVectorBase({
        content: JSON.stringify(queryContent),
        user_id: this.user_id,
      });
      let relateString = relateData.data.data
        .map((item) => item.content)
        .join(" ");

      this.isGenerate = true;
      let userMessage = {
        role: "user",
        content: `You are the owner and creator of the content provided below. Please respond to me in first-person angle to the question based on the following and previous content (if there is no relevant content, reply “Sorry, I have not been provided with relevant information to your request. You can try to send a message to our service representative.”).\nQuestion: ${this.currentM}\n Content: ${relateString}`,
        formatContent: this.currentM,
      };
      this.currentMessageList.push(userMessage);
      this.addToPublish(userMessage);
      this.scrollToBottom();
      this.currentM = "";
      this.currentMessageList.push({
        role: "assistant",
        content: "",
        relateData: relateData.data.data,
      });
      this.scrollToBottom();
      const that = this;
      const newIndex = this.currentIndex + 1;
      that.currentIndex = newIndex;
      this.loadtitle = "Generating AI Answers ...";
      const eventSource = fetchEventSource(
        "https://web-backend-sg.reachable-edu.com/AIChat/Test.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          openWhenHidden: true,
          body: JSON.stringify({
            model: "gpt-3.5-turbo",
            messages: that.currentMessageList.slice(
              0,
              that.currentMessageList.length - 1
            ),
          }),
          onopen(response) {
            console.log("连接已建立", response);
          },
          onmessage(event) {
            if (event.data === "Connection closed") {
              that.isGenerate = false;
              that.addToPublish(
                that.currentMessageList[that.currentMessageList.length - 1]
              );
              if (
                that.currentMessageList[
                  that.currentMessageList.length - 1
                ].content.indexOf(
                  "Sorry, I have not been provided with relevant information to your request. You can try to send a message to our service representative"
                ) != -1
              ) {
                saveFailChat({
                  user_id: that.user_id,
                  question:
                    that.currentMessageList[that.currentMessageList.length - 2]
                      .formatContent,
                });
              }
              return;
            }
            that.loading = false;
            console.log("收到消息", JSON.stringify(event.data));

            try {
              const result = JSON.parse(event.data);
              if (result.time && result.content) {
                that.currentMessageList[
                  that.currentMessageList.length - 1
                ].content += result.content;
              }
            } catch (error) {
              console.log(error);
            }
          },
          onclose() {},
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.agentContainer {
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  border: 1px solid #ddd;
  background: #fff;
  .left {
    width: 45%;
    border: 1px solid #ddd;
    border-right: none;
    border-radius: 10px 0 0 10px;
  }
  .main {
    border-radius: 0 10px 10px 0;
    flex: 1;
    overflow: hidden;
    position: relative;
    border: 1px solid #ddd;
    .messageContainer {
      width: 100%;
      margin: 0 auto;
      height: calc(100% - 100px);
      overflow-y: scroll;
      white-space: pre-wrap;
      line-height: 1.75;
      .messageItem {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 20px 20px;
        box-sizing: border-box;
        .chatusername {
          font-size: 16px;
          color: #444;
          margin-bottom: 10px;
          font-weight: bold;
        }
        .leftMessageItem {
          max-width: 80%;
          background: rgb(249, 249, 249);
          color: #777;
          border-radius: 10px;
          padding: 10px 20px;
          padding-bottom: 0;
        }
        .rightMessageItem {
          max-width: 80%;
          background: rgb(236, 101, 45);
          color: #fff;
          padding: 10px 20px;
          border-radius: 10px;
          padding-bottom: 0;
        }
        .right1 {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
      .messageItem.right {
        justify-content: flex-end;
      }
    }
    .inputC {
      text-align: center;
      width: 100%;
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      .mybutton {
        margin-left: 20px;
        background: #ec652b;
        border-color: #ec652b;
        position: absolute;
        right: 10px;
        bottom: 10px;
        border-radius: 10px;
      }
    }
  }
}
</style>
<style lang="scss">
.agentContainer {
  .myInput {
    box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    .el-textarea__inner {
      height: 100%;
    }
  }
  .publishItem {
    padding: 24px 20px;
    cursor: pointer;
    height: 140px;
    &:hover {
      background: rgb(249, 249, 249);
    }
    &.active {
      background: rgb(249, 249, 249);
    }
    .pulish_name {
      font-size: 18px;
      font-weight: bold;
      color: #444;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .time {
        font-size: 16px;
        color: #444;
        font-weight: normal;
      }
    }
    .content_txt {
      font-size: 16px;
      color: #999;
      margin-top: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}
</style>